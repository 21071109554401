
document.getElementById("btn_recent").addEventListener("click", (event) => {
  updatePortfolioHeadline("These are my latest photos");
  updateGallery("recent");
  hideWelcomeMessage(false);
});

updateGallery("recent");

function updatePortfolioHeadline(text) {
  let h2 = document.getElementById("portfolio_headline");
  h2.textContent = text;
}

function hideWelcomeMessage(hidden) {
  let div = document.getElementById("welcome_message");
  div.hidden = hidden;
}

async function updateGallery(name) {
    let response = await fetch("./json/" + name + '.json');
    let jsonData = await response.json();
    displayImages(jsonData);
}

function displayImages(jsonData) {
  let container = document.getElementById("gallery");
  while (container.firstChild) {
    container.removeChild(container.lastChild);
  }
  for (let i = jsonData.length - 1; i >= 0; i--) {
    let a = document.createElement("a");
    a.target = "_blank";
    a.href = jsonData[i].image;
    a.setAttribute("data-pswp-width", jsonData[i].width);
    a.setAttribute("data-pswp-height", jsonData[i].height);
    a.setAttribute("data-pswp-srcset", jsonData[i].srcset);
    let img = document.createElement("img");
    img.src = jsonData[i].thumbnail;
    img.alt = jsonData[i].createdate;
    a.appendChild(img);
    let caption = createCaption(jsonData[i]);
    a.appendChild(caption);
    container.appendChild(a);
  }
}

function createCaption(json) {
  let caption = document.createElement("span");
  caption.className = "pswp-caption-content";

  let table = document.createElement("table");
  table.className = "exif-table";

  appendCaptionTableProperty(table, "Photo was taken", json.createdate);
  appendCaptionTableProperty(table, "Body", json.make + " " + json.cameramodelname);
  appendCaptionTableProperty(table, "Lens", json.lensmodel );
  appendCaptionTableProperty(table, "Focal length 35 mm equivalent", json.focallengthin35mmformat);
  appendCaptionTableProperty(table, "Aperture", "f" + json.aperturevalue);
  appendCaptionTableProperty(table, "Exposure", json.exposuretime + " sec");
  appendCaptionTableProperty(table, "ISO", json.iso);
  if (json.flash === "No Flash") {
    appendCaptionTableProperty(table, "Flash used", "No");
  } else {
    appendCaptionTableProperty(table, "Flash used", "Yes");
  }
  appendCaptionTableProperty(table, "Copyright informationen", json.copyright );

  caption.appendChild(table);
  return caption;
}

function appendCaptionTableProperty( table, label, value) {
  let tr = document.createElement("tr");
  let th = document.createElement("th");
  th.textContent = label;
  tr.appendChild(th);
  table.appendChild(tr);
  tr = document.createElement("tr");
  let td = document.createElement("td");
  td.textContent = value;
  tr.appendChild(td);
  table.appendChild(tr);
}